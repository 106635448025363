import axios from "axios";
import { getToken, setToken } from "@/utils";
import authApi from "./authApi";
import socialMediaApi from "./socialMediaApi";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let refreshTokenAttempts = 0;
const MAX_REFRESH_ATTEMPTS = 3;

api.interceptors.response.use(
  async (response) => {
    if (response.status === 207) {
      if (response?.headers?.authorization) {
        const extractedToken = response.headers.authorization.replace(
          "Bearer ",
          "",
        );
        setToken(extractedToken);
      }
    }

    if (response?.data?.access_token) {
      setToken(response.data.access_token);
    }

    return response.data;
  },
  async (error) => {
    if (
      error?.response?.status === 401 &&
      refreshTokenAttempts < MAX_REFRESH_ATTEMPTS
    ) {
      refreshTokenAttempts++;
      await authApi.refreshToken();

      return api.request(error.config);
    }
    return Promise.reject(error);
  },
);

export default api;
export { socialMediaApi };
