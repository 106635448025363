import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BottomNavigation from "@/components/bottomNavigation";

const MainLayout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="mx-auto flex h-screen w-full flex-col">
      <div className="flex-1 pb-[62px] shadow">{children}</div>
      <BottomNavigation />
    </div>
  );
};

export default MainLayout;
