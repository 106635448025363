import { lazy } from "react";
const LoginPage = lazy(() => import("@/app/auth/login"));
const LoginStatus = lazy(() => import("@/app/auth/login/LoginStatus"));
const RegisterSuccessPage = lazy(() => import("@/app/auth/Success"));
const RegisterFailedPage = lazy(() => import("@/app/auth/Faild"));
const ForgetPasswordPage = lazy(() => import("@/app/auth/forget"));
const RegisterPage = lazy(() => import("@/app/auth/register"));

const HomePage = lazy(() => import("@/app/home/HomePage"));
const MyAccountDashboardPage = lazy(
  () => import("@/app/accountDashboard/AccountDashboard"),
);
const ProfileSettingPage = lazy(
  () => import("@/app/profileSetting/ProfileSetting"),
);
const ProfilePage = lazy(() => import("@/app/profileSetting/Profile"));
const SecurityPage = lazy(() => import("@/app/profileSetting/Security"));
const BindPage = lazy(() => import("@/app/profileSetting/Bind"));
const ApplyPage = lazy(() => import("@/app/profileSetting/Apply"));
const SkinMarkersPage = lazy(() => import("@/app/skinMarkers/SkinMarkers"));
const CustomerInfoPage = lazy(() => import("@/app/customerInfo/CustomerInfo"));
const ServiceProviderInfoPage = lazy(
  () => import("@/app/serviceProviderInfo/ServiceProviderInfo"),
);
const BookPage = lazy(() => import("@/app//book/Book"));
const BookDetailPage = lazy(() => import("@/app/book/BookDetail"));
const NotFoundPage = lazy(() => import("@/components/NotFound404"));
const LineCallBackPage = lazy(() => import("@/app/auth/LineCallback"));
export {
  RegisterPage,
  RegisterSuccessPage,
  RegisterFailedPage,
  ForgetPasswordPage,
  LoginPage,
  LoginStatus,
  HomePage,
  MyAccountDashboardPage,
  ProfileSettingPage,
  ProfilePage,
  SecurityPage,
  ApplyPage,
  SkinMarkersPage,
  CustomerInfoPage,
  ServiceProviderInfoPage,
  BookPage,
  BookDetailPage,
  NotFoundPage,
  BindPage,
  LineCallBackPage,
};
