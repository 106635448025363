import {
  RegisterPage,
  RegisterSuccessPage,
  RegisterFailedPage,
  ForgetPasswordPage,
  LoginPage,
  LoginStatus,
  HomePage,
  MyAccountDashboardPage,
  ProfileSettingPage,
  ApplyPage,
  SkinMarkersPage,
  CustomerInfoPage,
  ServiceProviderInfoPage,
  BookPage,
  LineCallBackPage,
  BookDetailPage,
  NotFoundPage,
  ProfilePage,
  SecurityPage,
  BindPage,
} from "@/app/index";

/**
 * @typedef {Object} RouteConfig
 * @property {string} path - 路徑
 * @property {React.ReactElement} component - 組件
 * @property {boolean} auth - 是否需要登入
 * @property {string[]} roles - 角色
 * @property {string} layout - 版型
 */
export const routes = [
  //註冊
  {
    path: "/register",
    component: <RegisterPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
  //註冊成功
  {
    path: "/register-success",
    component: <RegisterSuccessPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
  //註冊失敗
  {
    path: "/register-failed",
    component: <RegisterFailedPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
  //忘記密碼
  {
    path: "/forget-password",
    component: <ForgetPasswordPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
  //登入
  {
    path: "/login",
    component: <LoginPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },

  //登入
  {
    path: "/login-status",
    component: <LoginStatus />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },

  // line callback
  {
    path: "/call-back",
    component: <LineCallBackPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },

  //首頁
  {
    path: "/",
    component: <HomePage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //我的
  {
    path: "/my",
    component: <MyAccountDashboardPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //資料設定
  {
    path: "/profile-setting",
    component: <ProfileSettingPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 基本資料
  {
    path: "/profile-setting/profile",
    component: <ProfilePage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 已綁定美業老師名單
  {
    path: "/profile-setting/bind",
    component: <BindPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // 重設密碼
  {
    path: "/profile-setting/security",
    component: <SecurityPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //申請美業老師
  {
    path: "/profile-setting/apply",
    component: <ApplyPage />,
    auth: true,
    roles: ["customer"],
    layout: "main",
  },
  //膚況標記
  {
    path: "/skin-markers",
    component: <SkinMarkersPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //顧客資訊
  {
    path: "/customer-info/:id",
    component: <CustomerInfoPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //業者資訊
  {
    path: "/service_provider-info/:id",
    component: <ServiceProviderInfoPage />,
    auth: true,
    roles: ["customer", "organization"],
    layout: "main",
  },
  //預約
  {
    path: "/book",
    component: <BookPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  //預約單
  {
    path: "/book-detail/:id",
    component: <BookDetailPage />,
    auth: true,
    roles: ["customer", "service_provider", "organization"],
    layout: "main",
  },
  // not found
  {
    path: "*",
    component: <NotFoundPage />,
    auth: false,
    roles: ["customer", "service_provider", "organization"],
    layout: "empty",
  },
];
