import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import userApi from "@/services/userApi";
import authApi from "@/services/authApi";
import { removeToken } from "@/utils";
import userSchema from "@/schema/userSchema";
const userStore = createSlice({
  name: "user",
  initialState: {
    profile: localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile"))
      : null,
    isAuth:
      localStorage.getItem("isAuth") &&
      JSON.parse(localStorage.getItem("isAuth")) === true
        ? true
        : false,
    currentRole: localStorage.getItem("currentRole")
      ? JSON.parse(localStorage.getItem("currentRole"))
      : {
          role: userSchema.CURRENT_ROLE.CUSTOMER,
          identityId: null,
        },
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload;
    },
  },
});

export const { setProfile, setIsAuth, setCurrentRole } = userStore.actions;
const userReducer = userStore.reducer;

export const useUserStore = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const isAuth = useSelector((state) => state.user.isAuth);
  const currentRole = useSelector((state) => state.user.currentRole);
  const actions = {
    userLogin: async (account, password) => {
      try {
        await authApi.login(account, password);
        actions.setIsAuth(true);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    /**
     * 第三方登入
     * @param {'line'} platform 第三方平台
     */
    thirdPartyLogin: async (platform) => {
      try {
        const baseUrl = `${window.location.origin}/third-party`;
        const res = await authApi[`${platform}Login`](baseUrl);
        window.location.href = res.redirect_url;
      } catch (error) {
        console.error(error);
      }
    },
    setProfile: (profile) => {
      localStorage.setItem("profile", JSON.stringify(profile));
      dispatch(setProfile(profile));
    },
    fetchUserProfile: async () => {
      try {
        const res = await userApi.getUser();
        actions.setProfile(res);
        return res;
      } catch (error) {
        console.error(error);
        actions.setProfile(null);
        return null;
      }
    },
    setIsAuth: (isAuth) => {
      localStorage.setItem("isAuth", JSON.stringify(isAuth));
      dispatch(setIsAuth(isAuth));
    },
    userLogout: async () => {
      removeToken();
      localStorage.removeItem("profile");
      dispatch(setProfile(null));
      localStorage.removeItem("isAuth");
      dispatch(setIsAuth(false));
      localStorage.removeItem("bookingCodeShown");
      localStorage.removeItem("cart");
      localStorage.removeItem("currentRole");
      dispatch(
        setCurrentRole({
          role: userSchema.CURRENT_ROLE.CUSTOMER,
          identityId: null,
        }),
      );
    },
    setCurrentRole: (role, identityId) => {
      localStorage.setItem("currentRole", JSON.stringify({ role, identityId }));
      dispatch(setCurrentRole({ role, identityId }));
    },
  };

  return { profile, isAuth, currentRole, ...actions };
};

export default userReducer;
