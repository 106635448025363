import { useSelector, useDispatch } from "react-redux";
import {
  selectProfile,
  selectIsAuth,
  selectCurrentRole,
  selectCommunityUser,
  createActions,
} from "@/store/modules/user";

export const useUserStore = () => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const isAuth = useSelector(selectIsAuth);
  const currentRole = useSelector(selectCurrentRole);
  const communityUser = useSelector(selectCommunityUser);

  return {
    profile,
    isAuth,
    currentRole,
    communityUser,
    ...createActions(dispatch),
  };
};
