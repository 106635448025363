import {
  ResponsiveModal,
  ResponsiveModalTitle,
  ResponsiveModalContent,
  ResponsiveModalDescription,
} from "@/components/ui/responsive-modal";

const TipModal = ({
  children,
  message,
  open,
  onOpenChange,
  hideClose = false,
}) => {
  return (
    <ResponsiveModal open={open} onOpenChange={onOpenChange}>
      <ResponsiveModalContent hideClose={hideClose}>
        {/* 防出現錯誤 */}
        <ResponsiveModalTitle></ResponsiveModalTitle>
        <ResponsiveModalDescription></ResponsiveModalDescription>
        <div className="flex flex-col items-center justify-center gap-5">
          {children ? (
            // 如果有 children，則只顯示 children
            <div>{children}</div>
          ) : (
            <>
              {message && (
                <span className="whitespace-nowrap font-medium text-[#434343]">
                  {message}
                </span>
              )}
            </>
          )}
        </div>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

export default TipModal;
