import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
const PrivateWrapper = ({ children, auth, roles }) => {
  const { isAuth, userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  if (isAuth && location.pathname === "/login") {
    return <Navigate to="/" />;
  }
  // 需要登入但未登入, 導向登入頁;
  if (auth && !isAuth) {
    return <Navigate to="/login" />;
  }

  // 需要特定角色但使用者角色不符,導向首頁
  if (auth && roles && userInfo.role && !roles.includes(userInfo.role)) {
    return <Navigate to="/" />;
  }

  return children;
};
export default PrivateWrapper;
