import api from "./index";

const authApi = {
  /**
   * phone 註冊
   */
  phoneRegister: async (phone) => {
    try {
      const res = await api.post(`/public/register/phone`, null, {
        params: { phone_number: phone },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 寄送手機驗證碼 */
  sendPhoneVerification: async () => {
    try {
      const res = await api.post(`/private/register/verify/phone`);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 驗證手機驗證碼 */
  verifyPhoneVerification: async (code) => {
    try {
      const res = await api.post(
        `/private/register/verify/phone/confirm`,
        null,
        {
          params: { code },
        },
      );
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  /** email 註冊
   * @param {string} email
   */
  emailRegister: async (email) => {
    try {
      const res = await api.post(`/public/register/email`, null, {
        params: {
          email,
        },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 寄送email驗證碼 */
  sendEmailVerification: async () => {
    try {
      const res = await api.post(`/private/register/verify/email`);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 驗證email驗證碼
   * @param {string} code
   */
  verifyEmailVerification: async (code) => {
    const res = await api.post(`/private/register/verify/email/confirm`, null, {
      params: {
        code,
      },
    });
    return res;
  },
  /** 註冊密碼
   * @param {string} password
   */
  registerPassword: async (password) => {
    try {
      const res = await api.post(`/private/register/password`, null, {
        params: {
          password,
        },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 一般登入 */
  login: async (username, password) => {
    try {
      const res = await api.post(
        `/public/auth/login`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** line 登入 */
  lineLogin: async (redirectUrl) => {
    try {
      const res = await api.get(`/public/auth/line`, {
        params: {
          redirect_url: redirectUrl,
        },
      });
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /** 忘記密碼
   * @param {string} email
   */
  forgotPassword: async (phone) => {
    try {
      const res = await api.post(`/public/auth/forget`, null, {
        params: {
          phone,
        },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 驗證忘記密碼驗證碼
   * @param {string} code
   */
  verifyForgotPassword: async (code) => {
    try {
      const res = await api.post(`/private/auth/verifyCode`, null, {
        params: { code },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 設定新密碼
   * @param {string} password
   */
  setNewPassword: async (password) => {
    try {
      const res = await api.post(`/private/auth/reset`, null, {
        params: { password },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 更新token */
  refreshToken: async () => {
    const res = await api.post(`/private/auth/refresh`);
    return res;
  },
};

export default authApi;
