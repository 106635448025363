import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "@/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "@/store";
import "moment/locale/zh-cn";
import moment from "moment";
import { Toaster } from "@/components/ui/sonner";

const root = ReactDOM.createRoot(document.getElementById("root"));

moment.locale("zh-cn");
root.render(
  <>
    <Toaster position="top-right" />
    <Provider store={store}>
      <App />
    </Provider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
