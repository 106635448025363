import { Navigate, useLocation } from "react-router-dom";
import { useUserStore } from "@/store/modules/user";

const PrivateWrapper = ({ children, auth, roles }) => {
  const userStore = useUserStore();
  const location = useLocation();
  if (userStore.isAuth && location.pathname === "/login") {
    return <Navigate to="/" />;
  }
  // 需要登入但未登入, 導向登入頁;
  if (auth && !userStore.isAuth) {
    return <Navigate to="/login" />;
  }

  if (
    userStore.isAuth &&
    userStore.profile &&
    !userStore.profile?.phone_is_verified &&
    location.pathname !== "/phone-verification"
  )
    return <Navigate to="/phone-verification" />;

  // 需要特定角色但使用者角色不符,導向首頁
  // if (auth && roles && userInfo.role && !roles.includes(userInfo.role)) {
  //   return <Navigate to="/" />;
  // }

  return children;
};
export default PrivateWrapper;
