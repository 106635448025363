import { createSlice } from "@reduxjs/toolkit";

const testStore = createSlice({
  name: "test",
  initialState: {
    test: "test",
  },
  reducers: {
    setTest: (state, action) => {
      state.test = action.payload;
    },
  },
});

export const { setTest } = testStore.actions;
const testReducer = testStore.reducer;

export default testReducer;
