import { request } from "@/utils";

const userApi = {
  /** 取得用戶資料
   * @param {string} userId - 用戶 ID
   * @returns {Promise}
   */
  getProfile: async (userId) => {
    try {
      const res = await request.get(`/api/user/${userId}/info`);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 更新用戶資料 */
  updateProfile: async (userId, profile) => {
    try {
      const res = await request.patch(`/api/user/${userId}/info`, null, {
        params: {
          name: profile.name,
          email: profile.email,
          gender: profile.gender,
        },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default userApi;
