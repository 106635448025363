import api from "./index";

const userApi = {
  /** 取得使用者資料 */
  getUser: async () => {
    try {
      const res = await api.get(`/private/user/me`);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 設定email
   *  email: string
   */
  setEmail: async (email) => {
    try {
      const res = await api.patch(`/private/user/email`, null, {
        params: {
          email,
        },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 寄送綁定email驗證碼 */
  sendBindEmailOtp: async () => {
    try {
      const res = await api.post(`/private/user/email/verify`);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 驗證綁定email驗證碼
   *  code: string
   */
  verifyBindEmailOtp: async (code) => {
    try {
      const res = await api.post(`/private/user/email/verify/confirm`, null, {
        params: {
          code,
        },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 設定手機號碼
   *  phone: string
   */
  setPhone: async (phone) => {
    try {
      const res = await api.patch(`/private/user/phone`, null, {
        params: { phone: `+886${phone}` },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 寄送綁定手機號碼驗證碼 */
  sendBindPhoneOtp: async () => {
    try {
      const res = await api.post(`/private/user/phone/verify`);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 驗證綁定手機號碼驗證碼
   *  code: string
   */
  verifyBindPhoneOtp: async (code) => {
    try {
      const res = await api.post(`/private/user/phone/verify/confirm`, null, {
        params: { code },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /** 更新nickname
   *  nickname: string
   */
  updateNickname: async (nickname) => {
    const res = await api.patch(`/private/user/nickname`, null, {
      params: { nickname },
    });
    return res;
  },
  /** 更新生日
   *  birthday: yyyy-mm-dd
   */
  updateBirthday: async (birthday) => {
    const res = await api.patch(`/private/user/birthday`, null, {
      params: { birthday },
    });
    return res;
  },
  /** 更新性別
   *  gender: male, female
   */
  updateGender: async (gender) => {
    const res = await api.patch(`/private/user/gender`, null, {
      params: { gender_type: gender },
    });
    return res;
  },
  /** 更新大頭貼
   *  image: file
   */
  updateAvatar: async (picture) => {
    const res = await api.patch(`/private/user/picture`, picture, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  },
  /** 更新密碼
   *  password: string
   */
  updatePassword: async (password) => {
    try {
      const res = await api.patch(`/private/user/password`, null, {
        params: { password },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default userApi;
