import { Navigate, useLocation } from "react-router-dom";
import { useUserStore } from "@/hooks/useUserStore";

import userSchema from "@/schema/userSchema";

const PrivateWrapper = ({ children, auth, roles }) => {
  const userStore = useUserStore();
  const location = useLocation();

  const isAuthenticatedAndVerified =
    userStore.isAuth &&
    userStore.profile &&
    userStore.profile?.phone_is_verified;

  // 已驗證用戶的公開頁面重定向
  const publicPaths = ["/login", "/register", "/phone-verification"];
  if (isAuthenticatedAndVerified && publicPaths.includes(location.pathname)) {
    return <Navigate to="/" />;
  }

  // 需要登入但未登入
  if (auth && !userStore.isAuth) {
    return <Navigate to="/login" />;
  }

  // 已登入但未驗證手機，且不在驗證流程中
  if (
    userStore.isAuth &&
    userStore.profile &&
    !userStore.profile?.phone_is_verified &&
    !["/phone-verification", "/login"].includes(location.pathname)
  ) {
    return <Navigate to="/phone-verification" />;
  }

  // 限定身份才能進入
  // if (
  //   (location.pathname === `/shop/${userStore?.currentRole?.orgId}` ||
  //     location.pathname ===
  //       `/shop/${userStore?.currentRole?.orgId}/redemption-history` ||
  //     location.pathname ===
  //       `/shop/${userStore?.currentRole?.orgId}/redeem-scan`) &&
  //   userStore?.currentRole?.role !== userSchema.CURRENT_ROLE.SERVICE_PROVIDER
  // ) {
  //   return <Navigate to="/my/customer" />;
  // }

  // if (
  //   userStore.isAuth &&
  //   userStore?.currentRole?.role === userSchema.CURRENT_ROLE.SERVICE_PROVIDER &&
  //   userStore?.currentRole?.roleLevel !== 99 &&
  //   location.pathname !== `/shop/${userStore?.currentRole?.orgId}` &&
  //   location.pathname !==
  //     `/shop/${userStore?.currentRole?.orgId}/redemption-history` &&
  //   location.pathname !== `/shop/${userStore?.currentRole?.orgId}/redeem-scan`
  // ) {
  //   return <Navigate to={`/shop/${userStore?.currentRole?.orgId}`} />;
  // }

  // 需要特定角色但使用者角色不符,導向首頁
  // if (auth && roles && userInfo.role && !roles.includes(userInfo.role)) {
  //   return <Navigate to="/" />;
  // }

  return children;
};
export default PrivateWrapper;
