import api from './index';

/**
 * 社交媒體相關的 API 服務
 * 提供貼文、互動、媒體上傳和用戶資料相關的功能
 */
const socialMediaApi = {
  // ==================== [文件管理] ====================
  /**
   * 上傳多個圖片
   * @param {File[]} files - 要上傳的文件數組
   * @returns {Promise<Object>} 上傳結果
   */
  uploadImages: async (files) => {
    const formData = new FormData();

    // 遍歷所有文件並添加到 FormData，使用 'files' 作為參數名
    files.forEach(file => {
      formData.append('files', file);
    });

    return await api.post('/private/social_media/blob', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  /**
   * 上傳單個圖片 (為向後兼容而保留)
   * @param {File} file - 要上傳的單個文件
   * @returns {Promise<Object>} 上傳結果
   */
  uploadImage: async (file) => {
    return socialMediaApi.uploadImages([file]);
  },

  // ==================== [貼文管理] ====================
  /**
   * 創建新貼文
   * @param {Object} params - 參數對象
   * @param {Object} params.postData - 貼文數據，包括標題、內容、標籤等
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 創建結果
   */
  createPost: async (params) => {
    return await api.post('/private/social_media/post', params?.postData, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 更新現有貼文
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {Object} params.postData - 更新的貼文數據，包括標題、內容、標籤等
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 更新結果
   */
  updatePost: async (params) => {
    return await api.put(`/private/social_media/post/${params?.postId}`, params?.postData, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 刪除貼文
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 刪除結果
   */
  deletePost: async (params) => {
    return await api.delete(`/private/social_media/post/${params?.postId}`, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 獲取單個貼文詳情
   * @param {string|number} postId - 貼文ID
   * @returns {Promise<Object>} 貼文詳情
   */
  getPostById: async (postId) => {
    return await api.get(`/public/social_media/post/${postId}`);
  },

  /**
   * 獲取公開的貼文列表
   * @param {Object} params - 查詢參數，如分頁、排序等
   * @returns {Promise<Object>} 貼文列表
   */
  getPublicPosts: async (params) => {
    return await api.get(`/public/social_media/posts`, { params });
  },

  /**
   * 獲取私人的貼文列表
   * @param {Object} params - 查詢參數，可能包含 identity_id、org_id、分頁、排序等
   * @returns {Promise<Object>} 貼文列表
   */
  getPrivatePosts: async (params) => {
    return await api.get(`/private/social_media/posts`, { params });
  },

  /**
   * 根據標籤搜尋文章
   * @param {string} tag - 要搜尋的標籤名稱
   * @param {Object} [params] - 額外的查詢參數，如分頁、排序等
   * @returns {Promise<Object>} 符合標籤的文章列表
   */
  getPostsByTag: async (params) => {
    return await api.get(`/public/social_media/posts/byTag`, {
      params: params
    });
  },

  /**
   * 根據標籤名稱獲取文章列表
   * @param {Object} params - 參數對象
   * @param {string} params.tag - 標籤名稱
   * @param {string} [params.validFrom] - 有效期起始時間
   * @param {string} [params.order='desc'] - 排序方式 (asc 或 desc)
   * @param {number} [params.offset=0] - 分頁偏移量
   * @param {number} [params.limit=10] - 返回結果數量限制
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 符合標籤的文章列表
   */
  getPostsByTagName: async (params) => {
    return await api.get(`/public/social_media/posts/byTag`, {
      params: {
        tag: params?.tag,
        valid_from: params?.validFrom,
        order: params?.order || 'desc',
        offset: params?.offset || 0,
        limit: params?.limit || 10,
        ...params?.extraParams
      }
    });
  },

  /**
   * 根據分類獲取貼文列表
   * @param {Object} params - 參數對象
   * @param {string} params.categoryName - 分類名稱
   * @param {Object} [params.extraParams] - 額外的查詢參數，如分頁、排序等
   * @returns {Promise<Object>} 符合分類的貼文列表
   */
  getPostsByCategory: async (params) => {
    console.log('[getPostsByCategory] 參數:', params);
    return await api.get(`/public/social_media/posts/byCategory`, {
      params: {
        category_name: params?.categoryName,
        ...params?.extraParams
      }
    });
  },

  /**
 * 獲取熱門貼文列表
 * @param {Object} params - 查詢參數，如 valid_from、offset、limit、order 等
 * @returns {Promise<Object>} 熱門貼文列表
 */
  getPopularPosts: async (params) => {
    return await api.get('/public/social_media/posts/hot', { params });
  },

  // ==================== [評論功能] ====================
  /**
   * 添加評論
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {string} params.content - 評論內容
   * @returns {Promise<Object>} 添加評論結果
   */
  addComment: async (params) => {
    const requestParams = {
      ...(params?.identityId ? { identity_id: params.identityId } : {}),
      ...(params?.orgId ? { org_id: params.orgId } : {})
    };

    return await api.post(`/private/social_media/comment/${params?.postId}`, {
      text: params?.content
    }, {
      params: requestParams
    });
  },

  /**
   * 回覆評論
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string|number} params.srcId - 原始評論ID (要回覆的評論ID)
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {string} params.content - 評論內容
   * @returns {Promise<Object>} 添加評論結果
   */
  replyComment: async (params) => {
    const requestParams = {
      ...(params?.identityId ? { identity_id: params.identityId } : {}),
      ...(params?.orgId ? { org_id: params.orgId } : {}),
      ...(params?.srcId ? { replied_comment_id: params?.srcId } : {})
    };

    return await api.post(`/private/social_media/comment/${params?.postId}`, {
      text: params?.content,
    }, {
      params: requestParams
    });
  },

  /**
   * 刪除評論
   * @param {Object} params - 參數對象
   * @param {string|number} params.commentId - 評論ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @returns {Promise<Object>} 刪除評論結果
   */
  deleteComment: async (params) => {
    const requestParams = {
      ...(params?.identityId ? { identity_id: params.identityId } : {}),
      ...(params?.orgId ? { org_id: params.orgId } : {})
    };

    return await api.delete(`/private/social_media/comment/${params?.commentId}`, {
      params: requestParams
    });
  },

  /**
   * 文章作者刪除評論
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string|number} params.commentId - 評論ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 刪除評論結果
   */
  deleteCommentByAuthor: async (params) => {
    return await api.delete(`/private/social_media/comments/${params?.postId}`, {
      params: {
        comment_id: params?.commentId,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  // ==================== [互動功能] ====================
  /**
   * 點讚貼文
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 點讚結果
   */
  likePost: async (params) => {
    return await api.post('/private/social_media/like', null, {
      params: {
        post_id: params?.postId,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 取消點讚
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 取消點讚結果
   */
  unlikePost: async (params) => {
    return await api.post('/private/social_media/unlike', null, {
      params: {
        post_id: params?.postId,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 點讚評論
   * @param {Object} params - 參數對象
   * @param {string|number} params.commentId - 評論ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 點讚評論結果
   */
  likeComment: async (params) => {
    return await api.post('/private/social_media/like/comment', null, {
      params: {
        comment_id: params?.commentId,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 取消點讚評論
   * @param {Object} params - 參數對象
   * @param {string|number} params.commentId - 評論ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 取消點讚評論結果
   */
  unlikeComment: async (params) => {
    return await api.post('/private/social_media/unlike/comment', null, {
      params: {
        comment_id: params?.commentId,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 獲取用戶按讚文章列表
   * @param {Object} params - 查詢參數，可能包含 identity_id 或 org_id
   * @returns {Promise<Object>} 用戶按讚文章列表
   */
  getLikedPosts: async (params) => {
    console.log('[socialMediaApi] 獲取用戶按讚文章列表', params);
    return await api.get('/private/social_media/posts/liked', { params });
  },

  /**
   * 獲取用戶對特定文章已點讚的評論列表
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 已點讚評論列表
   */
  getLikedCommentsByPostId: async (params) => {
    return await api.get(`/private/social_media/comments/liked/${params?.postId}`, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 收藏貼文
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 收藏結果
   */
  bookmarkPost: async (params) => {
    return await api.post(`/private/social_media/post/${params?.postId}/collect`, null, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 取消收藏
   * @param {Object} params - 參數對象
   * @param {string|number} params.postId - 貼文ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 取消收藏結果
   */
  unbookmarkPost: async (params) => {
    return await api.delete(`/private/social_media/post/${params?.postId}/collect`, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 獲取用戶收藏文章列表
   * @param {Object} params - 查詢參數，可能包含 identity_id 或 org_id
   * @returns {Promise<Object>} 用戶收藏文章列表
   */
  getBookmarkedPosts: async (params) => {
    console.log('[socialMediaApi] 獲取用戶收藏文章列表', params);
    return await api.get('/private/social_media/posts/collected', { params });
  },

  // ==================== [用戶關係] ====================
  /**
   * 關注用戶
   * @param {Object} params - 參數對象
   * @param {string} params.userId - 要關注的用戶ID
   * @param {string} [params.identityId] - 當前用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 關注結果
   */
  followUser: async (params) => {
    return await api.post(`/private/social_media/follow/${params?.userId}`, null, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 取消關注用戶
   * @param {Object} params - 參數對象
   * @param {string} params.userId - 要取消關注的用戶ID
   * @param {string} [params.identityId] - 當前用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 取消關注結果
   */
  unfollowUser: async (params) => {
    return await api.post(`/private/social_media/unfollow/${params?.userId}`, null, {
      params: {
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  // ==================== [用戶資料] ====================
  /**
   * 獲取用戶的社群個人資料
   * @param {Object} params - 查詢參數，可能包含 identity_id 或 org_id
   * @returns {Promise<Object>} 用戶社群資料
   */
  getProfile: async (params) => {
    return await api.get(`/private/social_media/`, { params });
  },

  /**
   * 獲取公開的社交媒體個人檔案
   * @param {string} profileId - 個人檔案ID
   * @returns {Promise<Object>} 公開的個人檔案資料
   */
  getPublicProfile: async (params) => {
    return await api.get(`/public/social_media/profile/${params?.profileId}`);
  },

  /**
   * 更新用戶的個人簡介
   * @param {Object} params - 參數對象
   * @param {string} params.miscType - 雜項類型，例如: 'first'
   * @param {string} params.miscValue - 雜項值，例如: 'test'
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 更新結果
   */
  updateDescription: async (params) => {
    return await api.patch('/private/social_media/misc', null, {
      params: {
        misc_type: params?.miscType,
        misc_value: params?.miscValue,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 更新用戶的顯示名稱
   * @param {Object} params - 參數對象
   * @param {string} params.displayName - 新的顯示名稱
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 更新結果
   */
  updateDisplayName: async (params) => {
    return await api.patch('/private/social_media/display_name', null, {
      params: {
        display_name: params?.displayName,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 更新用戶的顯示用戶名
   * @param {Object} params - 參數對象
   * @param {string} params.displayUsername - 新的顯示用戶名
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 更新結果
   */
  updateDisplayUsername: async (params) => {
    return await api.patch('/private/social_media/display_username', null, {
      params: {
        display_username: params?.displayUsername,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 更新用戶的顯示頭像
   * @param {Object} params - 參數對象
   * @param {string} params.blobId - 頭像圖片的blob ID
   * @param {string} [params.identityId] - 用戶身份ID (可選)
   * @param {string} [params.orgId] - 組織ID (可選，當用戶為平台角色時使用)
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 更新結果
   */
  updateDisplayBlob: async (params) => {
    return await api.patch('/private/social_media/display_blob', null, {
      params: {
        blob_id: params?.blobId,
        ...(params?.identityId ? { identity_id: params.identityId } : {}),
        ...(params?.orgId ? { org_id: params.orgId } : {}),
        ...params?.extraParams
      }
    });
  },

  /**
   * 搜尋社交媒體用戶檔案
   * @param {Object} params - 搜尋參數
   * @param {string} [params.keyword] - 搜尋關鍵字
   * @param {string} [params.order='asc'] - 排序方式 (asc 或 desc)
   * @param {number} [params.limit=10] - 返回結果數量限制
   * @param {number} [params.offset] - 分頁偏移量
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 用戶檔案列表
   */
  searchProfiles: async (params) => {
    return await api.get('/public/social_media/profiles/search', {
      params: {
        keyword: params?.keyword,
        order: params?.order || 'asc',
        limit: params?.limit || 10,
        offset: params?.offset,
        ...params?.extraParams
      }
    });
  },

  /**
   * 搜尋標籤
   * @param {Object} params - 搜尋參數
   * @param {string} [params.keyword] - 搜尋關鍵字
   * @param {number} [params.limit=10] - 返回結果數量限制
   * @param {Object} [params.extraParams] - 額外的查詢參數
   * @returns {Promise<Object>} 標籤列表
   */
  searchTags: async (params) => {
    return await api.get('/public/social_media/tags/search', {
      params: {
        keyword: params?.keyword,
        limit: params?.limit || 10,
        ...params?.extraParams
      }
    });
  }
};

export default socialMediaApi;