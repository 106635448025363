import "@/App.css";
import Router from "@/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";

const queryClient = new QueryClient();

function isWebview() {
  var useragent = navigator.userAgent;
  var regex =
    /(WebView|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv|.0.0.0))/gi;
  return Boolean(useragent.match(regex));
}

function App() {
  useEffect(() => {
    if (isWebview()) {
      let currentUrl = window.location.href;
      if (!currentUrl.includes("?openExternalBrowser=1")) {
        currentUrl +=
          (currentUrl.includes("?") ? "&" : "?") + "openExternalBrowser=1";
        window.location.href = currentUrl;
      }
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </>
  );
}

export default App;
