import { lazy } from "react";
const LoginPage = lazy(() => import("@/app/auth/login"));

const ForgetPasswordPage = lazy(() => import("@/app/auth/forget"));
const ForgotPasswordPage = lazy(() => import("@/app/auth/forgot"));
const RegisterPage = lazy(() => import("@/app/auth/register"));
const ThirdPartyPage = lazy(() => import("@/app/auth/thirdParty"));
const PhoneVerificationPage = lazy(() => import("@/app/auth/phone"));
const EmailVerificationPage = lazy(() => import("@/app/auth/email"));
const HomePage = lazy(() => import("@/app/home/HomePage"));
const MyAccountDashboardPage = lazy(
  () => import("@/app/accountDashboard/AccountDashboard"),
);
const ProfileSettingPage = lazy(
  () => import("@/app/profileSetting/ProfileSetting"),
);
const ProfilePage = lazy(() => import("@/app/profileSetting/Profile"));
const SecurityPage = lazy(() => import("@/app/profileSetting/Security"));
const BindPage = lazy(() => import("@/app/profileSetting/Bind"));
const ApplyPage = lazy(() => import("@/app/profileSetting/Apply"));
const SkinMarkersPage = lazy(() => import("@/app/skinMarkers/SkinMarkers"));
const CustomerInfoPage = lazy(() => import("@/app/customerInfo/CustomerInfo"));
const ServiceProviderInfoPage = lazy(
  () => import("@/app/serviceProviderInfo/ServiceProviderInfo"),
);
const BookPage = lazy(() => import("@/app//book/Book"));
const BookDetailPage = lazy(() => import("@/app/book/BookDetail"));
const NotFoundPage = lazy(() => import("@/components/NotFound404"));

export {
  RegisterPage,
  ForgetPasswordPage,
  LoginPage,
  HomePage,
  MyAccountDashboardPage,
  ProfileSettingPage,
  ProfilePage,
  SecurityPage,
  ApplyPage,
  SkinMarkersPage,
  CustomerInfoPage,
  ServiceProviderInfoPage,
  BookPage,
  BookDetailPage,
  NotFoundPage,
  BindPage,
  ThirdPartyPage,
  PhoneVerificationPage,
  EmailVerificationPage,
  ForgotPasswordPage,
};
