import { z } from "zod";

const userSchema = {
  profile: z.object({
    nickname: z.string().min(1, { message: "Nickname is required" }),
    birthday: z.string().min(1, { message: "Birthday is required" }),
    email: z.string().email({ message: "請輸入正確的Email" }).optional(),
  }),
  organization: z.object({
    organization_name: z.string().min(1, {
      message: "Organization name is required",
    }),
    organization_phone: z.string().min(1, { message: "Phone is required" }),
    organization_address: z.string().min(1, { message: "Address is required" }),
    organization_email: z.string().email({ message: "Invalid email" }),
    region_id: z.string().min(1, { message: "Region is required" }),
    service_region_ids: z.array(z.string()).min(1, {
      message: "Service region is required",
    }),
  }),
  CURRENT_ROLE: {
    CUSTOMER: "customer",
    SERVICE_PROVIDER: "service_provider",
  },
};

export default userSchema;
