import { createSlice } from "@reduxjs/toolkit";
import userApi from "@/services/userApi";
import authApi from "@/services/authApi";
import socialMediaApi from "@/services/socialMediaApi";
import { removeToken } from "@/utils";
import userSchema from "@/schema/userSchema";

// Selectors
export const selectProfile = (state) => state.user.profile;
export const selectIsAuth = (state) => state.user.isAuth;
export const selectCurrentRole = (state) => state.user.currentRole;
export const selectCommunityUser = (state) => state.user.communityUser;

// Initial state helpers
const getInitialProfile = () =>
  localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null;

const getInitialIsAuth = () =>
  localStorage.getItem("isAuth") &&
  JSON.parse(localStorage.getItem("isAuth")) === true;

const getInitialCurrentRole = () =>
  localStorage.getItem("currentRole")
    ? JSON.parse(localStorage.getItem("currentRole"))
    : {
      role: userSchema.CURRENT_ROLE.CUSTOMER,
      identityId: null,
    };

// 不再從localStorage初始化communityUser
const getInitialCommunityUser = () => null;

// Slice
const userStore = createSlice({
  name: "user",
  initialState: {
    profile: getInitialProfile(),
    isAuth: getInitialIsAuth(),
    currentRole: getInitialCurrentRole(),
    communityUser: getInitialCommunityUser(),
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload;
    },
    setCommunityUser: (state, action) => {
      state.communityUser = action.payload;
    },
  },
});

// Actions
export const createActions = (dispatch) => ({
  userLogin: async (account, password, from) => {
    try {
      if (from) {
        localStorage.setItem("from", from);
      }
      await authApi.login(account, password);
      await createActions(dispatch).fetchUserProfile();
      createActions(dispatch).setIsAuth(true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  thirdPartyLogin: async (platform, from) => {
    try {
      const baseUrl = `${window.location.origin}/third-party`;
      const res = await authApi[`${platform}Login`](baseUrl);
      window.location.href = res.redirect_url;
      if (from) {
        localStorage.setItem("from", from);
      }
    } catch (error) {
      console.error(error);
    }
  },

  fetchUserProfile: async () => {
    try {
      const res = await userApi.getUser();
      localStorage.setItem("profile", JSON.stringify(res));
      dispatch(setProfile(res));
      return res;
    } catch (error) {
      console.error(error);
      dispatch(setProfile(null));
      localStorage.removeItem("profile");
      return null;
    }
  },

  fetchCommunityUser: async () => {
    try {
      // 從存儲中取得當前角色
      const currentUserRole = getInitialCurrentRole();

      console.log('[store/user] 獲取社群用戶數據，當前角色:', currentUserRole);

      // 如果沒有 identityId，表示是顧客，還是有社群個人資料

      const res = await socialMediaApi.getProfile({
        identity_id: currentUserRole.identityId,
      });
      console.log('[store/user] 獲取到社群用戶數據:', res);

      dispatch(setCommunityUser(res));
      return res;
    } catch (error) {
      console.error("[store/user] 獲取社群用戶數據失敗:", error);
      dispatch(setCommunityUser(null));
      return null;
    }
  },

  getCurrentRole: () => {
    // 從localStorage獲取當前角色，避免循環依賴
    const currentRoleString = localStorage.getItem("currentRole");
    return currentRoleString ? JSON.parse(currentRoleString) : null;
  },

  setIsAuth: (isAuth) => {
    localStorage.setItem("isAuth", JSON.stringify(isAuth));
    dispatch(setIsAuth(isAuth));
  },

  setCurrentRole: (role, identityId, orgId, roleLevel) => {
    const roleData = { role, identityId, orgId, roleLevel };
    localStorage.setItem(
      "currentRole",
      JSON.stringify(roleData),
    );
    dispatch(setCurrentRole(roleData));

    // 不再自動觸發fetchCommunityUser，讓useCommunityUser中的useEffect來處理
    // 這樣可以避免多次重複調用
    // setTimeout(() => {
    //   createActions(dispatch).fetchCommunityUser();
    // }, 0);
  },

  userLogout: async () => {
    removeToken();
    localStorage.removeItem("profile");
    localStorage.removeItem("isAuth");
    localStorage.removeItem("bookingCodeShown");
    localStorage.removeItem("cart");
    localStorage.removeItem("currentRole");
    localStorage.removeItem("from");
    localStorage.setItem("isLogout", true);

    dispatch(setProfile(null));
    dispatch(setIsAuth(false));
    dispatch(setCommunityUser(null));
    dispatch(
      setCurrentRole({
        role: userSchema.CURRENT_ROLE.CUSTOMER,
        identityId: null,
        orgId: null,
        roleLevel: null,
      }),
    );
  },
});

export const { setProfile, setIsAuth, setCurrentRole, setCommunityUser } = userStore.actions;
export default userStore.reducer;
