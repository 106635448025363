import Webcam from "react-webcam";
import { useState, useRef, useCallback, useEffect } from "react";
import TipModal from "@/components/modal/TipModal";
import { ArrowsCounterClockwise } from "@phosphor-icons/react";
import { useLocation } from "react-router-dom";

// 在文件頂部添加常量
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const WebCamera = ({ onCapture }) => {
  const [availableCameras, setAvailableCameras] = useState([]);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [hasPermission, setHasPermission] = useState(true);
  const [isCamReady, setIsCamReady] = useState(false);
  const webcamRef = useRef(null);

  const videoConstraints = {
    facingMode: facingMode,
    // frameRate: { ideal: 30 },
    width: { ideal: 1280 },
    height: { ideal: 720 },
  };

  // 檢測是否為 iPhone
  const isIPhone = /iPhone/.test(navigator.userAgent);

  // if (isIPhone) {
  //   videoConstraints.width = { ideal: 2560 };
  //   videoConstraints.height = { ideal: 1440 };
  // }

  const checkAvailableCameras = async () => {
    setIsCamReady(true);

    // 取得裝置是否支援相機
    const supportsCamera = await navigator.mediaDevices.getUserMedia({
      video: true,
    });

    if (supportsCamera) {
      console.log("supportsCamera");
    } else {
      console.log("not supportsCamera");
    }
  };

  const requestCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setHasPermission(true);
      await checkAvailableCameras();
    } catch (err) {
      console.log("no permission");
      setHasPermission(false);
    }
  };

  useEffect(() => {
    // requestCameraPermission();
  }, []);

  const handleSwitchCamera = useCallback(() => {
    setFacingMode((prevMode) =>
      prevMode === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  }, []);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();

    if (imageSrc) {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        // 保持原始解析度，不縮小canvas尺寸
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);

        // 使用較高品質的JPEG壓縮
        const croppedImageSrc = canvas.toDataURL("image/jpeg", 1.0);
        onCapture(croppedImageSrc);
      };
      img.src = imageSrc;
    }
  }, [webcamRef, onCapture]);

  // 沒有權限時顯示提示
  if (hasPermission === false) {
    return (
      <TipModal open={true} onOpenChange={() => {}}>
        <div className="flex flex-col items-center justify-center gap-4">
          <p className="text-center text-lg font-medium">無法取得相機權限</p>
          <p className="text-center text-base text-gray-600">
            請確認相機權限是否開啟，或嘗試使用其他瀏覽器
          </p>
          <button
            className="rounded-lg bg-gradient-to-r from-[#FF7373] to-[#FFC5C5] px-12 py-2 text-lg text-white shadow-[0px_2px_4px_0px_#00000026] transition-colors hover:opacity-90"
            onClick={requestCameraPermission}
          >
            重新授權相機
          </button>
        </div>
      </TipModal>
    );
  }

  return (
    <div className="relative h-screen w-screen">
      <div className="relative flex h-full w-full items-center justify-center bg-black">
        {/* {isCamReady && ( */}
        {/* <Webcam
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          audio={false}
          videoConstraints={videoConstraints}
          onUserMediaError={(error) => {
            console.log(error);
            if (error.message.includes("Could not start video source")) {
              // setFacingMode(
              //   facingMode === "environment" ? "user" : "environment",
              // );
              // setHasPermission(false);
            }
          }}
          mirrored={true}
          forceScreenshotSourceSize
          className={`h-full w-full object-cover`}
        /> */}
        <Webcam
          ref={webcamRef}
          audio={false}
          videoConstraints={videoConstraints}
          className="h-full w-full object-cover"
        />
        {/* )} */}
      </div>

      <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-between">
        <div className="h-[15%] w-full bg-gradient-to-b from-[rgba(205,61,61,0.8)] to-[rgba(249,139,139,0)]"></div>

        <div className="flex h-full w-full items-center justify-center px-8">
          <div className="relative aspect-[3/4] h-[80%] max-h-[80vh] w-auto">
            {/* 左上角 */}
            <div className="absolute left-0 top-5 h-12 w-12 rounded-tl-lg border-l-[3px] border-t-[3px] border-white" />
            {/* 右上角 */}
            <div className="absolute right-0 top-5 h-12 w-12 rounded-tr-lg border-r-[3px] border-t-[3px] border-white" />
            {/* 左下角 */}
            <div className="absolute bottom-5 left-0 h-12 w-12 rounded-bl-lg border-b-[3px] border-l-[3px] border-white" />
            {/* 右下角 */}
            <div className="absolute bottom-5 right-0 h-12 w-12 rounded-br-lg border-b-[3px] border-r-[3px] border-white" />
            {/* 中間虛線 */}
            <div className="absolute left-1/2 h-full w-[2px] -translate-x-1/2 border-l-2 border-dashed border-white" />
          </div>
        </div>

        <div className="flex w-full flex-col gap-5 bg-gradient-to-b from-[rgba(208,208,208,0)] to-[rgba(255,255,255,0.05)] py-5 backdrop-blur-[5px]">
          <div className="text-center">
            <p className="text-white/80">
              於室內正常光照拍攝、避免強光、逆光、昏暗
            </p>
          </div>
          <div className="pointer-events-auto relative flex items-center justify-center gap-8">
            <div
              className="z-20 flex h-[72px] w-[72px] cursor-pointer items-center justify-center rounded-full bg-white"
              onClick={capture}
            >
              <div className="h-16 w-16 rounded-full border-2 border-gray-200" />
            </div>
            {
              <div
                className="absolute right-1/4 flex translate-x-1/2 cursor-pointer items-center justify-center"
                onClick={handleSwitchCamera}
              >
                <ArrowsCounterClockwise size={32} color="#fff" />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebCamera;
