import { z } from "zod";

const userSchema = {
  profile: z.object({
    nickname: z.string().min(1, { message: "請輸入暱稱" }),
    birthday: z.string().min(1, { message: "請輸入生日" }),
    email: z.string().email({ message: "請輸入正確的Email" }).optional(),
  }),
  organization: z.object({
    organization_name: z.string().min(1, {
      message: "請輸入組織名稱",
    }),
    organization_phone: z.string().min(1, { message: "請輸入電話" }),
    organization_address: z.string().min(1, { message: "請輸入地址" }),
    organization_email: z.string().email({ message: "請輸入正確的Email" }),
    region_id: z.string().min(1, { message: "請選擇地區" }),
    service_region_ids: z.array(z.string()).min(1, {
      message: "請選擇服務地區",
    }),
  }),
  CURRENT_ROLE: {
    CUSTOMER: "customer",
    SERVICE_PROVIDER: "service_provider",
  },
};

export default userSchema;
