import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import PrivateWrapper from "./ProtectedRoute";
import { Suspense } from "react";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MainLayout from "@/components/layout/MainLayout";
import EmptyLayout from "@/components/layout/EmptyLayout";
import { RegisterProvider } from "@/context/RegisterContext";

export default function Router() {
  return (
    <RegisterProvider>
      <BrowserRouter>
        <Suspense
          fallback={
            <Flex align="center" justify="center" style={{ height: "100dvh" }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </Flex>
          }
        >
          <Routes>
            {routes.map(({ path, component, auth, roles, layout }) => {
              let LayoutComponent;

              // 根據路由配置選擇相應的 Layout
              switch (layout) {
                case "main":
                  LayoutComponent = MainLayout;
                  break;
                case "empty":
                  LayoutComponent = EmptyLayout;
                  break;
                default:
                  LayoutComponent = EmptyLayout;
                  break;
              }
              return (
                <Route
                  path={path}
                  key={path}
                  element={
                    <PrivateWrapper auth={auth} roles={roles}>
                      <LayoutComponent>{component}</LayoutComponent>
                    </PrivateWrapper>
                  }
                />
              );
            })}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </RegisterProvider>
  );
}
